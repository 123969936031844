// extracted by mini-css-extract-plugin
export var container = "work-navigation-module--container--ODTIC";
export var prev = "work-navigation-module--prev--EV+3Y";
export var button = "work-navigation-module--button--BH9CR";
export var next = "work-navigation-module--next--cydx3";
export var imageContainer = "work-navigation-module--image-container--X-rEe";
export var topText = "work-navigation-module--top-text--dFAzM";
export var arrowLeft = "work-navigation-module--arrow-left--KGBj9";
export var arrowRight = "work-navigation-module--arrow-right--Plbyk";
export var image = "work-navigation-module--image--dGcUA";
export var bottomText = "work-navigation-module--bottom-text--giFug";
export var arrowContainer = "work-navigation-module--arrow-container--9o5Wu";
export var arrowContainerLeft = "work-navigation-module--arrow-container-left--ErQDS";
export var arrowContainerRight = "work-navigation-module--arrow-container-right--Xqmxw";