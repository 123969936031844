import React from 'react';

import { container, title as titleText, boxes, box } from './award-list.module.scss';
import { IAward } from '../../models/awards.model';

import AwardCard from '../molecules/award-card';

interface IAwardListProps {
    className?: string;
    awards: IAward[];
    title?: string;
    TitleTag?: React.ElementType;
}

const AwardList: React.FC<IAwardListProps> = ({
    className = '',
    awards,
    TitleTag = 'h4',
    title,
}) => {
    return (
        <div className={`${className} ${container}`}>
            {title && <TitleTag className={titleText}>Awards</TitleTag>}
            <ul className={boxes}>
                {awards.map((award) => {
                    return (
                        <li key={award.awardId} className={box}>
                            <AwardCard award={award} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default AwardList;
