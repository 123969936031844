import React from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
    container,
    button,
    prev,
    next,
    image,
    imageContainer,
    topText,
    arrowContainer,
    arrowContainerLeft,
    arrowContainerRight,
    arrowRight,
    arrowLeft,
    bottomText,
} from './work-navigation.module.scss';
import ArrowLeft from '../../assets/images/svg/arrow-left.svg';
import ArrowRight from '../../assets/images/svg/arrow-right.svg';
import pageContexts from '../../config/page-contexts';
import { relations } from '../../config/relations';
import { translationKeys } from '../../config/translation-keys';
import { IWork, IWorkCard } from '../../models/work.model';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import useMedia from '../../hooks/use-media';
import { useTranslation } from '../../hooks/use-translation';

import Button from '../atoms/button';

interface IWorkNavigationProps {
    className?: string;
    work: IWork;
    previousWork: IWorkCard | undefined;
    nextWork: IWorkCard | undefined;
}

const WorkNavigation: React.FC<IWorkNavigationProps> = ({
    className = '',
    previousWork,
    nextWork,
}) => {
    const { t } = useI18next();

    const { media: previousMedia } = previousWork || {};
    const { media: nextMedia } = nextWork || {};

    const prevImage = useMedia({ media: previousMedia, relation: relations.thumbnailHorizontal });
    const nextImage = useMedia({ media: nextMedia, relation: relations.thumbnailHorizontal });

    const { title: titlePrev, clientContent: clientPrev } = useTranslation(
        previousWork,
        translationKeys.workCard
    );
    const { title: titleNext, clientContent: clientNext } = useTranslation(
        nextWork,
        translationKeys.workCard
    );

    return (
        <div className={`${className} ${container}`}>
            {previousWork && (
                <Link to={previousWork.slug} className={`${imageContainer} ${prev}`}>
                    {prevImage.image && (
                        <GatsbyImage
                            image={prevImage.image}
                            className={image}
                            alt={prevImage.alt || ' '}
                        />
                    )}
                    <div className={`${arrowContainer} ${arrowContainerLeft}`}>
                        <ArrowLeft className={arrowLeft} />
                    </div>
                    <p className={topText}>{titleNext}</p>
                    <p className={bottomText}>{clientPrev}</p>
                </Link>
            )}
            <Button as="link" to={pageContexts.works.slug} className={button}>
                {t('work.navigation.button')}
            </Button>
            {nextWork && (
                <Link to={nextWork.slug} className={`${imageContainer} ${next}`}>
                    {nextImage.image && (
                        <GatsbyImage
                            image={nextImage.image}
                            className={image}
                            alt={nextImage.alt || ' '}
                        />
                    )}
                    <div className={`${arrowContainer} ${arrowContainerRight}`}>
                        <ArrowRight className={arrowRight} />
                    </div>
                    <p className={topText}>{titlePrev}</p>
                    <p className={bottomText}>{clientNext}</p>
                </Link>
            )}
        </div>
    );
};

export default WorkNavigation;
