import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layout,
    container,
    topVideo,
    shortDescription,
    header,
    titleText,
    contentText,
    boxes,
    boxTitle,
    boxText,
    workAward,
    workLayout,
    workNavigation,
} from './work.module.scss';
import { gridMedium, gridSmall } from '../styles/grid.module.scss';
import pageContexts from '../config/page-contexts';
import { relations } from '../config/relations';
import { translationKeys } from '../config/translation-keys';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IAward } from '../models/awards.model';
import { IWork, IWorkCard } from '../models/work.model';
import { useTranslation } from '../hooks/use-translation';
import useMedia from '../hooks/use-media';
import { getNodes } from '../utils/get-nodes';
import { getBreadcrumbItem } from '../utils/get-breadcrumbs';

import MainLayout from '../layouts/main-layout';
import VideoPlayer from '../components/molecules/video-player';
import AwardList from '../components/organisms/award-list';
import WorkLayout from '../components/organisms/work-layout';
import WorkNavigation from '../components/organisms/work-navigation';
import Markdown from '../components/hoc/markdown';

interface IWorkProps {
    readonly data: {
        work: IWork;
        nextWork?: IWorkCard;
        previousWork?: IWorkCard;
        allAward: IQueryAllResult<IAward>;
    };
}

const Work: React.FC<IWorkProps> = ({ data }) => {
    const { work, previousWork, nextWork, allAward } = data;
    const { t } = useI18next();

    const { media } = work;
    const awards = getNodes(allAward);

    const {
        title,
        lead,
        content,
        subTitle1,
        subTitle2,
        subTitle3,
        subContent1,
        subContent2,
        subContent3,
        videoUrl,
        clientContent,
        mediaContent,
    } = useTranslation(work, translationKeys.work);

    const breadcrumbs = [getBreadcrumbItem(pageContexts.works), getBreadcrumbItem(work, 'title')];
    const ogImage = useMedia({ media, relation: relations.thumbnailHorizontal });

    const showBoxes =
        subTitle1 || subTitle2 || subTitle3 || subContent1 || subContent2 || subContent3;

    return (
        <MainLayout
            isVisibleHeaderBg={true}
            className={`${layout} ${gridMedium}`}
            SEOProps={{ title, description: lead, image: ogImage.url }}
            breadcrumbs={breadcrumbs}
        >
            <div className={`${gridSmall} ${container}`}>
                {videoUrl && (
                    <div className={topVideo}>
                        <VideoPlayer url={videoUrl} />
                    </div>
                )}

                {(clientContent || mediaContent) && (
                    <div className={shortDescription}>
                        {clientContent && (
                            <p>
                                {t('work.client')}: <span> {clientContent}</span>
                            </p>
                        )}
                        {mediaContent && (
                            <p>
                                {t('work.media')}: <span>{mediaContent}</span>
                            </p>
                        )}
                    </div>
                )}

                <div className={header}>
                    <h1 className={titleText}>{title}</h1>
                    {content && <Markdown className={contentText}>{content}</Markdown>}
                </div>

                {showBoxes && (
                    <div className={boxes}>
                        {(subTitle1 || subContent1) && (
                            <div>
                                {subTitle1 && <h2 className={boxTitle}>{subTitle1}</h2>}
                                {subContent1 && (
                                    <Markdown className={boxText}>{subContent1}</Markdown>
                                )}
                            </div>
                        )}
                        {(subTitle2 || subContent2) && (
                            <div>
                                {subTitle2 && <h2 className={boxTitle}>{subTitle2}</h2>}
                                {subContent2 && (
                                    <Markdown className={boxText}>{subContent2}</Markdown>
                                )}
                            </div>
                        )}
                        {(subTitle3 || subContent3) && (
                            <div>
                                {subTitle3 && <h2 className={boxTitle}>{subTitle3}</h2>}
                                {subContent3 && (
                                    <Markdown className={boxText}>{subContent3}</Markdown>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {awards.length > 0 && (
                    <AwardList className={workAward} awards={awards} title={t('work.awards')} />
                )}
                <WorkLayout className={workLayout} work={work} />
                <WorkNavigation
                    className={workNavigation}
                    work={work}
                    previousWork={previousWork}
                    nextWork={nextWork}
                />
            </div>
        </MainLayout>
    );
};

export default Work;

export const query = graphql`
    query (
        $language: String!
        $workId: Int!
        $previousWorkId: Int
        $nextWorkId: Int
        $hasPrevious: Boolean!
        $hasNext: Boolean!
        $awardIds: [Int]!
    ) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        work(workId: { eq: $workId }) {
            ...workFields
        }

        previousWork: work(workId: { eq: $previousWorkId }) @include(if: $hasPrevious) {
            ...workCardFields
        }

        nextWork: work(workId: { eq: $nextWorkId }) @include(if: $hasNext) {
            ...workCardFields
        }

        allAward(filter: { awardId: { in: $awardIds } }) {
            edges {
                node {
                    ...awardFields
                }
            }
        }
    }
`;
