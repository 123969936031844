// extracted by mini-css-extract-plugin
export var ratio = "work-layout-module--ratio--CBsuY";
export var ratioBottom = "work-layout-module--ratio-bottom--6DLEI";
export var containerSmall = "work-layout-module--container-small--x1SiC";
export var containerWide = "work-layout-module--container-wide--aI8sQ";
export var imgFirst = "work-layout-module--img-first--souXI";
export var boxes = "work-layout-module--boxes--+XKjw";
export var box = "work-layout-module--box--R+hkL";
export var boxContent = "work-layout-module--box-content--CunqY";
export var boxImg = "work-layout-module--box-img--suk1Y";
export var boxTitle = "work-layout-module--boxTitle--unin1";
export var boxText = "work-layout-module--boxText--sejVZ";
export var title = "work-layout-module--title--TJj7T";
export var imgThird = "work-layout-module--imgThird--5erAp";