import React from 'react';

import {
    containerSmall,
    containerWide,
    imgFirst,
    boxes,
    box,
    boxImg,
    boxContent,
    boxTitle,
    boxText,
    title,
    imgThird,
    ratio,
    ratioBottom,
} from './work-layout.module.scss';
import { relations } from '../../config/relations';
import { translationKeys } from '../../config/translation-keys';
import { IWork } from '../../models/work.model';
import { useTranslation } from '../../hooks/use-translation';
import useMedia from '../../hooks/use-media';

import Markdown from '../../components/hoc/markdown';
import RatioImage from '../atoms/ratio-image';

interface IWorkLayoutProps {
    className?: string;
    work: IWork;
}

const IWorkLayoutProps: React.FC<IWorkLayoutProps> = ({ className = '', work }) => {
    const { layoutTitle1, layoutTitle2, layoutContent1, layoutContent2, bottomTitle } =
        useTranslation(work, translationKeys.work);

    const { media } = work;

    const { image: topImage } = useMedia({ media, relation: relations.layoutImageTop });
    const { image: leftImage } = useMedia({ media, relation: relations.layoutImageLeft });
    const { image: rightImage } = useMedia({ media, relation: relations.layoutImageRight });
    const { image: bottomImage } = useMedia({ media, relation: relations.layoutImageBottom });

    const showLayout =
        topImage ||
        leftImage ||
        rightImage ||
        bottomImage ||
        layoutTitle1 ||
        layoutTitle2 ||
        layoutContent1 ||
        layoutContent2 ||
        bottomTitle;

    if (!showLayout) return null;

    return (
        <div className={className}>
            <div className={containerSmall}>
                {topImage && (
                    <RatioImage image={topImage} ratioClass={ratio} className={imgFirst} />
                )}

                <div className={boxes}>
                    {leftImage && layoutTitle1 && layoutContent1 && (
                        <div className={box}>
                            <RatioImage image={leftImage} ratioClass={ratio} className={boxImg} />

                            <div className={boxContent}>
                                <h5 className={boxTitle}>{layoutTitle1}</h5>
                                {layoutContent1 && (
                                    <Markdown className={boxText}>{layoutContent1}</Markdown>
                                )}
                            </div>
                        </div>
                    )}

                    {rightImage && layoutTitle2 && layoutContent2 && (
                        <div className={box}>
                            {rightImage && (
                                <RatioImage
                                    image={rightImage}
                                    ratioClass={ratio}
                                    className={boxImg}
                                />
                            )}
                            <div className={boxContent}>
                                <h5 className={boxTitle}>{layoutTitle2}</h5>
                                {layoutContent2 && (
                                    <Markdown className={boxText}>{layoutContent2}</Markdown>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className={containerWide}>
                {bottomTitle && <h5 className={title}>{bottomTitle}</h5>}
                {bottomImage && (
                    <RatioImage image={bottomImage} ratioClass={ratioBottom} className={imgThird} />
                )}
            </div>
        </div>
    );
};

export default IWorkLayoutProps;
