// extracted by mini-css-extract-plugin
export var layout = "work-module--layout--NQ2Pn";
export var container = "work-module--container--33ck6";
export var topVideo = "work-module--top-video--WgynN";
export var shortDescription = "work-module--short-description--oKWGD";
export var header = "work-module--header--Ulij-";
export var titleText = "work-module--title-text--bznS2";
export var contentText = "work-module--content-text--TLSxd";
export var boxes = "work-module--boxes--xYFJY";
export var boxTitle = "work-module--box-title--c8yem";
export var boxText = "work-module--box-text--sG5GC";
export var workAward = "work-module--work-award--SAwuP";
export var workLayout = "work-module--work-layout--RtJPz";
export var workNavigation = "work-module--workNavigation--xQqAl";